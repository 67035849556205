import React, { PropsWithChildren } from 'react';
import styles from './styles.module.css';
import SVGIcon from '../SVGIcon';
import { gameDataInstance } from '../api';

interface IProps extends PropsWithChildren {
    step: 1 | 0 | 2;
}

const GameWrapper: React.FC<IProps> = ({ children, step }) => {
    const hide = async () => {
        if (step === 1) {
            await gameDataInstance.updateAch(1);
        }

        const item = document.getElementById('start-popup');

        if (item) {
            item.style.display = 'none';
        }
    };

    return (
        <div className={styles.wrapper}>
            {children}
            <SVGIcon
                name='close_20'
                width={20}
                height={20}
                className={styles.closeIcon}
                onClick={hide}
            />
        </div>
    );
};

export default GameWrapper;
