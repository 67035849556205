import React from 'react';
import styles from './styles.module.css';
import SVGIcon from '../../../SVGIcon';
import cn from 'classnames';

export const ChooseCity: React.FC = () => (
    <div className={cn(styles.min3Wrapper, styles.chooseWrapper)}>
        <h3 className={styles.largeTitle}>Правила игры</h3>
        <p className={cn(styles.text, styles.chooseTextUp)}>
            Вам нужно будет выбрать, к какому городу относится изображение
        </p>
        <div className={styles.chooseCityImgWrapper}>
            <img
                className={styles.chooseCityImg}
                src='/images/game/choose_city.png'
            />
        </div>
        <p className={cn(styles.text, styles.chooseTextDown)}>
            После того, как вы ознакомились, нажмите далее
        </p>
    </div>
);
