export const gameData = [
    {
        image: '/images/game/process/milan_buenos.jpg',
        variants: ['Милан', 'Буэнос-Айрес'],
        correctIndex: 0,
    },
    {
        image: '/images/game/process/doha_newyork.jpg',
        variants: ['Доха', 'Нью-Йорк'],
        correctIndex: 0,
    },
    {
        image: '/images/game/process/london_saintp.jpg',
        variants: ['Санкт-Петербург', 'Лондон'],
        correctIndex: 1,
    },
    {
        image: '/images/game/process/singapore_osaka.jpg',
        variants: ['Осака', 'Сингапур'],
        correctIndex: 1,
    },
    {
        image: '/images/game/process/saintp_doha.jpg',
        variants: ['Санкт-Петербург', 'Доха'],
        correctIndex: 0,
    },
    {
        image: '/images/game/process/newyork_singapore.jpg',
        variants: ['Сингапур', 'Нью-Йорк'],
        correctIndex: 1,
    },
    {
        image: '/images/game/process/osaka_milan.jpg',
        variants: ['Осака', 'Милан'],
        correctIndex: 0,
    },
    {
        image: '/images/game/process/bueno_london.jpg',
        variants: ['Буэнос-Айрес', 'Лондон'],
        correctIndex: 0,
    },
];
