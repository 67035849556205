import React from 'react';
import styles from './styles.module.css';
import SVGIcon from '../../../SVGIcon';
import cn from 'classnames';

export const GoodLuck: React.FC = () => (
    <div>
        <h3 className={styles.largeTitle}>Удачи!</h3>
    </div>
);
