import React from 'react';
import styles from './styles.module.css';
import SVGIcon from '../../../SVGIcon';

type IProps = {
    step: number;
};

export const Min3: React.FC<IProps> = ({ step }) => (
    <div className={styles.min3Wrapper}>
        <h2 className={styles.largeTitle}>Угадайте город</h2>
        <h3 className={styles.mediumTitle}>Правила игры</h3>
        {step === 0 ? (
            <div className={styles.timerWrapper}>
                <SVGIcon name='clock_34' width={34} height={34} />
            </div>
        ) : (
            <img
                className={styles.ruleCityImg}
                src='/images/game/rule_city.png'
            />
        )}
        <p className={styles.text}>
            У вас есть ровно 3 минуты на прохождение игры
        </p>
    </div>
);
