import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import wrapperStyles from '../styles.module.css';
import cn from 'classnames';
import SVGIcon from '../../SVGIcon';
import { useTimer } from 'react-timer-hook';
import { hideGame } from '../../helpers/gameOpener';
import { gameData } from '../../helpers/gameData';
import { gameDataInstance } from '../../api';

const addMinutes = (date: Date, minutes: number) => {
    date.setMinutes(date.getMinutes() + minutes);

    return date;
};

const Process: React.FC = () => {
    const [startCounter, setStartCounter] = useState(3);
    const [isGameStarted, setIsGameStarted] = useState(false);
    const [isStartCounterEnabled, setIsStartCounterEnabled] = useState(true);
    const [endingResultPassed, setEndingResultPassed] = useState<
        boolean | undefined
    >(undefined);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [result, setResult] = useState<boolean[]>([]);
    const [isPaused, setIsPaused] = useState(false);
    const currentQuestionData = gameData[currentQuestion];

    const availableStartNumber = [3, 2, 1, 0];

    const onExpire = () => {
        setEndingResultPassed(false);
        setIsGameStarted(false);
    };

    const { minutes, seconds, start, restart, pause, resume } = useTimer({
        expiryTimestamp: addMinutes(new Date(), 3),
        onExpire,
        autoStart: false,
    });

    const answer = (idx: 1 | 0) => {
        const isCorrect = gameData[currentQuestion].correctIndex === idx;
        const newResult = result.concat(isCorrect);

        setResult(newResult);

        const element = document.getElementById('game-wrapper');
        element?.classList.toggle(
            isCorrect ? wrapperStyles.correct : wrapperStyles.incorrect
        );

        setTimeout(async () => {
            element?.classList.toggle(
                isCorrect ? wrapperStyles.correct : wrapperStyles.incorrect
            );

            if (gameData.length - 1 > currentQuestion) {
                setCurrentQuestion(old => old + 1);
            } else {
                const isPassed = newResult.every(res => res);

                setIsGameStarted(false);
                setEndingResultPassed(isPassed);
                pause();

                if (isPassed) {
                    await gameDataInstance.updateAch(3);
                }
            }
        }, 400);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (isStartCounterEnabled) {
                if (startCounter === -1) {
                    start();
                    setIsGameStarted(true);

                    clearInterval(interval);
                    setIsStartCounterEnabled(false);
                } else {
                    setStartCounter(old => old - 1);
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [startCounter, start, isStartCounterEnabled]);

    const startAgain = () => {
        setEndingResultPassed(undefined);
        setIsStartCounterEnabled(true);
        setStartCounter(3);
        setCurrentQuestion(0);
        setResult([]);
        restart(addMinutes(new Date(), 3), false);
    };

    const onPause = () => {
        console.log('pause');
        setIsPaused(true);
        pause();
    };

    const onContinue = () => {
        console.log('continue');
        setIsPaused(false);
        resume();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.left}>
                    <div className={styles.name}>До конца игры</div>
                    <div className={styles.value}>
                        0{minutes}:{seconds < 10 ? '0' + seconds : seconds}
                    </div>
                </div>
                {isGameStarted && (
                    <div className={styles.center}>
                        <div className={styles.name}>Пауза</div>
                        <div className={cn(styles.value, styles.pause)}>
                            {isPaused ? (
                                <div onClick={onContinue}>
                                    <SVGIcon
                                        name='start_17'
                                        width={17}
                                        height={17}
                                    />
                                </div>
                            ) : (
                                <div onClick={onPause}>
                                    <SVGIcon
                                        name='pause_17'
                                        width={17}
                                        height={17}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className={styles.right}>
                    <div className={styles.name}>Угадано городов</div>
                    <div className={styles.value}>
                        {result.filter(res => res).length}
                    </div>
                </div>
            </div>
            {!isGameStarted && startCounter >= 0 && (
                <div className={styles.startCounterFlex}>
                    <div className={styles.startCounterWrapper}>
                        {availableStartNumber.map(number => (
                            <div
                                key={number}
                                className={cn(styles.number, {
                                    [styles.left]: startCounter === number - 1,
                                    [styles.main]: startCounter === number,
                                    [styles.right]: startCounter === number + 1,
                                    [styles.tooLeft]: number - startCounter > 1,
                                    [styles.tooRight]:
                                        startCounter - number > 1,
                                })}
                            >
                                {number}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {isGameStarted && (
                <div className={styles.questionWrapper}>
                    <img
                        className={styles.questionImg}
                        src={process.env.PUBLIC_URL + currentQuestionData.image}
                    />
                    <div className={styles.variants}>
                        <button
                            onClick={() => answer(0)}
                            className={cn(styles.variantButton, {
                                [styles.variantButtonDisabled]: isPaused,
                            })}
                            disabled={isPaused}
                        >
                            {currentQuestionData.variants[0]}
                        </button>
                        <button
                            onClick={() => answer(1)}
                            className={cn(styles.variantButton, {
                                [styles.variantButtonDisabled]: isPaused,
                            })}
                            disabled={isPaused}
                        >
                            {currentQuestionData.variants[1]}
                        </button>
                    </div>
                </div>
            )}
            {endingResultPassed === true && (
                <div className={styles.resultWrapper}>
                    <h2 className={styles.resultTitle}>
                        Поздравляем, вы выиграли!
                    </h2>
                    <p className={styles.resultText}>
                        Дарим сертификат МТС Premium на 30 дней, <br />
                        который придет на электронную почту.
                    </p>
                    <img
                        className={styles.mtsImg}
                        src='/images/game/mts_premium.png'
                    />
                    {/* <p className={styles.resultText}>
                        Подарок будет отправлен на ваш адрес электронной почты
                    </p> */}
                    <div className={styles.replayButtons}>
                        <button
                            className={cn(styles.variantButton)}
                            onClick={hideGame}
                        >
                            <SVGIcon name='right_14' width={14} height={14} />
                            ВЫЙТИ ИЗ ИГРЫ
                        </button>
                    </div>
                </div>
            )}

            {endingResultPassed === false && (
                <div className={styles.resultWrapper}>
                    <h2 className={styles.resultTitle}>Игра завершена</h2>
                    <p className={styles.resultText}>
                        До конца уровня дойти не удалось, <br />
                        но это отличный повод попробовать еще раз
                    </p>
                    <div className={styles.replayButtons}>
                        <button
                            className={cn(styles.variantButton, styles.varLeft)}
                            onClick={startAgain}
                        >
                            <SVGIcon name='right_14' width={14} height={14} />
                            НАЧАТЬ ЗАНОВО
                        </button>
                        <button
                            className={cn(
                                styles.variantButton,
                                styles.buttonExit
                            )}
                            onClick={hideGame}
                        >
                            ВЫЙТИ ИЗ ИГРЫ
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Process;
