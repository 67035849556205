import React, { PropsWithChildren, useState } from 'react';
import styles from './styles.module.css';
import SVGIcon from '../../SVGIcon';
import { Min3 } from './Parts/Min3';
import { ChooseCity } from './Parts/ChooseCity';
import { MTSPremium } from './Parts/MTSPremium';
import { GoodLuck } from './Parts/GoodLuck';
import { Step } from '../../types/gameTypes';

const RuleComponent = [Min3, Min3, ChooseCity, MTSPremium, GoodLuck];

type IProps = {
    setCurrentStep: (step: string) => void;
};

const Rules: React.FC<IProps> = ({ setCurrentStep: setCurrentStepFunc }) => {
    const [currentStep, setCurrentStep] = useState(0);

    const goNext = () => {
        if (currentStep < 4) {
            setCurrentStep(currentStep + 1);
        }
    };

    const goBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const startGame = () => {
        setCurrentStepFunc(Step.game);
    };

    const leftMultiplier = currentStep - 1;

    const stepperLeft =
        leftMultiplier === -1 ? `0%` : `${leftMultiplier * 25}%`;

    const Component = RuleComponent[currentStep] as any;

    return (
        <>
            <div className={styles.content}>
                <Component step={currentStep} />
            </div>
            <div className={styles.buttonsWrapper}>
                {currentStep > 0 && currentStep !== 4 && (
                    <button onClick={goBack}>
                        <SVGIcon name='left_14' width={14} height={14} />
                        НАЗАД
                    </button>
                )}
                {currentStep < 4 && (
                    <button onClick={goNext}>
                        <SVGIcon name='right_14' width={14} height={14} />
                        ДАЛЕЕ
                    </button>
                )}
                {currentStep === 4 && (
                    <button onClick={startGame}>
                        <SVGIcon name='right_14' width={14} height={14} />
                        НАЧАТЬ ИГРУ
                    </button>
                )}
            </div>
            <div className={styles.stepper}>
                <div
                    className={styles.stepperLight}
                    style={{
                        left: stepperLeft,
                    }}
                />
            </div>
        </>
    );
};

export default Rules;
