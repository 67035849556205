import React from 'react';
import styles from './styles.module.css';
import SVGIcon from '../../../SVGIcon';
import cn from 'classnames';

export const MTSPremium: React.FC = () => (
    <div className={styles.min3Wrapper}>
        <h3 className={styles.largeTitle}>Правила игры</h3>
        <p className={cn(styles.text, styles.mtsText)}>
            Определите верно все города и получите подарок&nbsp;&mdash; <br />{' '}
            МТС Premium на 60 дней
        </p>
        <img className={styles.mtsImg} src='/images/game/mts_premium.png' />
    </div>
);
