import React, { useState } from 'react';
import Wrapper from './Wrapper';
import styles from './styles.module.css';
import SVGIcon from '../SVGIcon';
import classNames from 'classnames';

function StepTrip() {
    return (
        <>
            <h2 className={styles.largeTitle}>
                Путешествия —<br />
                символ статуса
            </h2>
            <p className={classNames(styles.text, styles.textScroll)}>
                Что общего между свежим асфальтовым полотном в оживлённом
                деловом районе, таком как Лондонский Сити, старой кирпичной
                мостовой на торговых артериях Ковент-Гардена, деревянными
                настилами на набережной у Канэри-Уорф вокруг старых торговых
                доков, бетонными плитами на пирсе хелипорта в Баттерси,
                викторианской брусчаткой из песчаника в Белгравии, вечнозелёным
                газоном в Гайд-Парке? &nbsp;&nbsp;Один из основателей теории
                вероятности, Блез Паскаль, говорил, что все человеческие
                несчастья имеют один корень – неумение спокойно оставаться у
                себя в комнате, но было бы как раз невероятным обнаружить всё
                вышеперечисленное у себя под ногами, не выйдя из своей комнаты
                хотя бы на улицу. Эту улицу – или набережную, или средневековую
                площадь, или тенистый сквер, или античную агору – вы каждый раз
                обнаружите в новом городе в прежде неизведанной вами части света
                и она будет заполнена одновременно знакомым и новым: похожими и
                в то же время иными звуками и запахами, архитектурой и
                вывесками, вариантами ритма жизни и времени суток, когда эта
                жизнь протекает. Города везде чем-то похожи, потому что в них
                везде живут люди, но мы исследуем их – поодиночке, в тандеме или
                компании друзей – ради того разнообразия форм, какие они
                принимают. Только отправившись в путешествие мы можем на себе
                прочувствовать тот гений места, тот стиль жизни, которые
                по-разному характеризуют каждый такой очаг цивилизации. Кто-то в
                каждом новом городе исследует музеи, чтобы узнать и лучше
                понять, как здесь складывалось прошлое – кто-то идёт в галереи,
                чтобы прощупать пульс современного общества через рождающееся в
                настоящем искусство. Одного привлекает архитектура и история,
                другой же проводит дегустацию местной кухни и напитков,
                систематично исследуя местные рестораны и бары.
                &nbsp;&nbsp;Такое соприкосновение с реальностью в другой части
                мира – реальностью местного искусства, истории или гастрономии –
                обогащает нас напоминая, что за пределами череды будней есть
                что-то большее – стоит только “выйти из комнаты”.
            </p>
            <div className={styles.icons}></div>
        </>
    );
}

export default StepTrip;
