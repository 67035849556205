import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './App.css';
import StartPopup from './StartPopup/StartPopup';
import { gameDataInstance } from './api';
import { updateAch } from './helpers/gameOpener';

const start = async () => {
    const data = await gameDataInstance.getInitialData();
    (window as any).gameData = data;
    (window as any).updateAch = updateAch;

    const root = ReactDOM.createRoot(
        document.getElementById('game') as HTMLElement
    );

    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );

    if (!data.achievement_1) {
        const item = document.getElementById('start-popup');

        if (item) item.style.display = 'flex';

        const root2 = ReactDOM.createRoot(
            document.getElementById('start-popup') as HTMLElement
        );

        root2.render(
            <React.StrictMode>
                <StartPopup />
            </React.StrictMode>
        );
    }
};

start();
