import React, { useState } from 'react';
import Wrapper from './Wrapper';
import styles from './styles.module.css';
import SVGIcon from '../SVGIcon';
import cn from 'classnames';

function Step() {
    return (
        <>
            <h2 className={styles.largeTitle}>В комнате спрятан предмет</h2>
            <p className={styles.textBold}>
                Найдите предмет в комнате,&nbsp;
                <br className={styles.brDesk} />
                связанный
                <br className={styles.brMobile} /> с путешествием
            </p>
            <p className={cn(styles.text, styles.textMobile)}>
                Пройдите небольшую игру, получите
                <br />
                гарантированный подарок - сертификат MTS
                <br />
                PREMIUM!
            </p>
            <div className={styles.icons}>
                <div className={styles.iconWrap}>
                    <SVGIcon name='degree' width={36} height={36} />
                </div>

                <div className={styles.iconWrap}>
                    <SVGIcon name='search' width={19} height={19} />
                </div>
                <div className={styles.iconWrap}>
                    <SVGIcon name='cubs' width={33} height={32} />
                </div>
            </div>
            <p className={cn(styles.text, styles.textDesk)}>
                Пройдите небольшую игру, получите гарантированный
                <br />
                подарок - сертификат MTS PREMIUM!
            </p>
        </>
    );
}

export default Step;
