import React, { PropsWithChildren, useState } from 'react';
import Rules from '../Rules/index';
import Process from '../Process/index';
import { Step } from '../../types/gameTypes';
import wrapperStyles from '../styles.module.css';
import SVGIcon from '../../SVGIcon';
import { hideGame } from '../../helpers/gameOpener';
import cn from 'classnames';

const steps = {
    [Step.rules]: Rules,
    [Step.game]: Process,
};

const Steps: React.FC<PropsWithChildren> = ({ children }) => {
    const [currentStep, setCurrentStep] = useState(Step.rules);
    const Component = steps[currentStep];

    return (
        <div
            className={cn(wrapperStyles.wrapper, {
                [wrapperStyles.wrapperRule]: currentStep === Step.rules,
                [wrapperStyles.wrapperGame]: currentStep === Step.game,
            })}
            id='game-wrapper'
        >
            <Component setCurrentStep={setCurrentStep} />
            <SVGIcon
                name='close_20'
                width={20}
                height={20}
                className={wrapperStyles.closeIcon}
                onClick={hideGame}
            />
        </div>
    );
};

export default Steps;
