import React, { useState } from 'react';
import './App.css';
import GameWrapper from './Game/GameWrapper';
import Steps from './Game/Steps';
import { hideGame, openGame, initialClose } from './helpers/gameOpener';

function Game() {
    (window as any).openGame = openGame;
    (window as any).hideGame = hideGame;
    (window as any).initialClose = initialClose;

    return <Steps />;
}

export default Game;
