import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://sobranie.ru/backend/api/',
    headers: {
        'Content-Type': 'application/json',
    },
});

export type InitialReturn = {
    achievement_1: boolean;
    achievement_2: boolean;
    achievement_3: boolean;
    achievement_4: boolean;
    achievement_5: boolean;
    achievement_6: boolean;
    achievement_7: boolean;
    achievement_8: boolean;
    achievement_9: boolean;
};

class GameData {
    public data: InitialReturn | undefined = undefined;

    public key: string | undefined = undefined;

    public getInitialData = async (): Promise<{
        achievement_1: boolean;
        achievement_2: boolean;
        achievement_3: boolean;
        achievement_4: boolean;
        achievement_5: boolean;
        achievement_6: boolean;
        achievement_7: boolean;
        achievement_8: boolean;
        achievement_9: boolean;
    }> => {
        if (this.data) {
            return this.data;
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const accessToken = urlParams.get('access_token');

        if (accessToken) {
            this.key = accessToken;

            localStorage.setItem('access_token', accessToken);

            const data = await instance.post('profile?v=2', {
                key: accessToken,
            });

            const ach = data.data.result.achievements;

            this.data = {
                achievement_1: ach.achievement_1,
                achievement_2: ach.achievement_2,
                achievement_3: ach.achievement_3,
                achievement_4: ach.achievement_4,
                achievement_5: ach.achievement_5,
                achievement_6: ach.achievement_6,
                achievement_7: ach.achievement_7,
                achievement_8: ach.achievement_8,
                achievement_9: ach.achievement_9,
            };

            return {
                achievement_1: ach.achievement_1,
                achievement_2: ach.achievement_2,
                achievement_3: ach.achievement_3,
                achievement_4: ach.achievement_4,
                achievement_5: ach.achievement_5,
                achievement_6: ach.achievement_6,
                achievement_7: ach.achievement_7,
                achievement_8: ach.achievement_8,
                achievement_9: ach.achievement_9,
            };
        }

        return {
            achievement_1: true,
            achievement_2: true,
            achievement_3: true,
            achievement_4: true,
            achievement_5: true,
            achievement_6: true,
            achievement_7: true,
            achievement_8: true,
            achievement_9: true,
        };
    };

    public updateAch = async (id: number) => {
        if (this.key) {
            const data = await instance.post('achievement', {
                key: this.key,
                id,
            });

            if (data.data.error !== 1) {
                parent.postMessage('updateProfile', '*');
                console.log((window as any).gameData);
                (window as any).gameData['achievement_' + id] = true;
            }
        }
    };
}

export const gameDataInstance = new GameData();
