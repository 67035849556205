import React, { useState } from 'react';
import Wrapper from './Wrapper';
import Step from './Step';
import SVGIcon from '../SVGIcon';
import styles from './styles.module.css';
import StepTrip from './StepTrip';
import { gameDataInstance } from '../api';

const StartPopup: React.FC = () => {
    const [step, setStep] = useState<0 | 1 | 2>(0);

    const closeAll = () => {
        const item = document.getElementById('start-popup');

        if (item) {
            item.style.display = 'none';
        }
    };

    const onNext = async () => {
        if (step === 0) {
            setStep(1);
        } else {
            await gameDataInstance.updateAch(1);
            setStep(2);
        }
    };

    if (step === 2) {
        return (
            <div className={styles.moveWrapper} onClick={closeAll}>
                <div className={styles.moveContent}>
                    <SVGIcon name='cursor_47' width={47} height={47} />
                    <div className={styles.moveText}>
                        Перемещайтесь по Студии S и найдите предмет,
                        <br />
                        связанный с путешествиями
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Wrapper step={step}>
            {step === 0 && <Step />}
            {step === 1 && <StepTrip />}
            <button className={styles.nextBtn} onClick={onNext}>
                <SVGIcon name='right_14' width={14} height={14} />
                {step === 0 ? 'ПРОДОЛЖИТЬ' : 'Перейти к поиску'}
            </button>
        </Wrapper>
    );
};

export default StartPopup;
