import { gameDataInstance } from '../api';

export const openGame = async () => {
    const gameElement = document.getElementById('game');

    if (gameElement) {
        gameElement.style.display = 'flex';
    }

    await gameDataInstance.updateAch(2);
};

export const hideGame = () => {
    const gameElement = document.getElementById('game');

    if (gameElement) {
        gameElement.style.display = 'none';
    }
};

export const updateAch = async (id: number) => {
    await gameDataInstance.updateAch(id);
};

export const initialClose = () => {
    const el = document.getElementById('start-popup');

    if (el) {
        el.style.zIndex = '0';
    }
};
